function createForm(modelValue: any) {
    const sendFormData = new FormData();

    Object.keys(modelValue).map(prop => {
        if (modelValue[prop] || typeof (modelValue[prop]) == 'number')
            sendFormData.append(prop, modelValue[prop])
    });

    return sendFormData;
}

function deepRef(obj: any, path?: any): string {
    if (path.length == 1) return obj[path];

    const currItem = path.shift();
    return obj[currItem] ? deepRef(obj[currItem], path) : '';
}

function toFormVars(fileModel: any) {
    const propsPath = getObjectPropsPaths(fileModel);
    const propsColl: any = {};

    propsPath.map(path => {
        const pathParts = path.split('.');
        const propName = pathParts[0] + pathParts.slice(1).map(p => `[${p}]`).join('');
        const propValue = deepRef(fileModel, pathParts);

        if (propValue !== undefined)
            propsColl[propName] = propValue;
    })


    return propsColl;
}

export {
    createForm,
    toFormVars
}