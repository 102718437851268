import { useAxios } from '@/composables/useAxios'
import { NUXT_APP_BIBLE_API } from '~/env';

const { axios, loading } = useAxios();
const yourbibleApi = NUXT_APP_BIBLE_API;

export async function uploadFile(uploadModel: any) {
    const formData = createForm(uploadModel);
    const formOptions = {
        headers: { "Content-Type": "multipart/form-data" },
    };

    const file = axios.post(yourbibleApi + `/uploads/uploadfile`, formData, formOptions);
    return file;
}

export async function removeFile(filename: any) {
    if (!filename)
        return console.log(`removeFile error: missing ${filename}`);

    return axios.delete(yourbibleApi + `/uploads/deletefile/${filename}`);
}

export { loading }