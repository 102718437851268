export function toDateFormat(dateValue: any, pattern: string, locale = 'en-US') {

    if (!dateValue) return '';

    const dateItem = new Date(dateValue.toString());

    let pad = (num: number) => (num < 10) ? '0' + num : num.toString();

    const formats = {
        "YYYY": (d: Date) => d.getFullYear(),
        "YY": (d: Date) => d.getFullYear().toString().slice(2, 4),
        "MM": (d: Date) => pad(d.getMonth() + 1),
        "ML": (d: Date) => capitalize(d.toLocaleString(locale, { month: 'long' })),
        "Mon": (d: Date) => capitalize(d.toLocaleString(locale, { month: 'short' })),
        "WDL": (d: Date) => capitalize(d.toLocaleString(locale, { weekday: 'long' })),
        "WDS": (d: Date) => capitalize(d.toLocaleString(locale, { weekday: 'short' })),
        "dd": (d: Date) => pad(d.getDate()),
        "DD": (d: Date) => d.getDate(),
        "hh": (d: Date) => pad(d.getHours()),
        "mm": (d: Date) => pad(d.getMinutes()),
        "ss": (d: Date) => pad(d.getSeconds()),
        "ms": (d: Date) => d.getTime()
    }

    Object.entries(formats).map(([k, v]) => pattern = pattern.replace(k, v(dateItem).toString()));

    return pattern;
}

export function differenceBetweenDates(date1: Date, date2: Date) {
    const tsDifference = date1.getTime() - date2.getTime();
    return Math.floor(tsDifference / (1000 * 60 * 60 * 24));
}

export function addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
}

export function getRange(start: any, end: any) {

    const range = [];

    //convert to date
    start = typeof start == 'string' ? new Date(start) : start;
    end = typeof end == 'string' ? new Date(end) : end;

    end = addDays(end, 1);
    end.setHours(0, 0, 0);


    for (let d = start; d.getTime() < end.getTime(); d = addDays(new Date(d), 1)) {

        const dateValue = new Date(d);
        dateValue.setHours(0, 0, 0);

        range.push(dateValue);
    }

    return range;
}


export function dayOfYear(date: Date) {

    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);

    return day;
}

export function yearOf(date: string) {
    return new Date(date).getFullYear();
}