import {
    loading,
    removeFile as _removeFile,
    uploadFile as _uploadFile,
} from "./upload.api";

export function useUpload() {

    async function uploadFile(fileModel: any) {
        return _uploadFile(fileModel);
    }

    async function removeFile(filepath: string) {
        const filename = filepath.replace(/\\$/, '').split('\\')?.pop()?.split('/')?.pop();
        return _removeFile(filename);
    }

    return {
        uploadFile,
        removeFile,
        loading
    }
}