<script setup>
import { useUpload } from "@/composables/useUpload";
import { useBanners } from "@/composables/useBanners";
import { resolveImage } from "@/composables/useImage";
import { toDateFormat } from "@/utils/date";

const props = defineProps(["banner"]);
const hover = ref(false);

const { removeFile } = useUpload();
const { removeBanner, fetchBanners } = useBanners();

async function handleRemove() {
  const { success } = await removeFile(props.banner.banner_src);

  if (success) {
    await removeBanner(props.banner._id);
    await fetchBanners();
  }
}
</script>
<template>
  <div class="banner-card" @mouseenter="hover = true" @mouseleave="hover = false">
    <img :src="resolveImage(banner?.banner_src)" class="banner" />
    <div class="banner-date">
      {{ toDateFormat(banner?.banner_created_at, "DD Mon YY, hh:mm") }}
    </div>

    <div class="btn-wrapper" :class="{ visible: hover }">
      <v-btn class="close-btn" @click="handleRemove">
        <v-svg
          class="pa-1"
          src="/icons/close-icon.svg"
          :options="{ width: '20px', height: '20px' }"
        ></v-svg>
      </v-btn>
    </div>
  </div>
</template>
<style scoped>
.banner-card {
  position: relative;
}
.banner {
  max-width: 200px;
  max-height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.btn-wrapper {
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(50%, -50%, 0);
  transition: all 0.2s ease;
  border-radius: 50%;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
}

.btn-wrapper.visible {
  visibility: visible;
  opacity: 1;
}
.close-btn {
  border-radius: 50%;
  color: rgb(250, 250, 250);
  background-color: rgb(231, 231, 231);
}

.banner-date {
  font-size: 12px;
  display: inline;
  background: rgba(34, 34, 34, 0.575);
  color: #fff;
  padding: 4px 10px;
  border-radius: 20px;
  position: relative;
  bottom: 20px;
  left: 0px;
}

.close-btn:active {
  background-color: rgb(44, 44, 44);
}
</style>
