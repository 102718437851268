export function isString(_object: any){
    return _object?.__proto__?.constructor.name == 'String'
}

export function getFirstLetter(text: string) {
    const trimValue: any = text?.trim() || '';
    return trimValue[0];
}

export function getInitials(text: string) {
    const trimValue: any = text?.trim().replace('  ',' ') || '';
    const [ first, second ] = trimValue.split(' ') || {};
    const firstLetter = first ? first[0] : '';
    const secondLetter = second ? second[0] : '';

    return firstLetter + secondLetter
}

export function wordsOf(text: any) {
    return text.replace(' ', ' ').trim().split(' ');
}

export function toTimerString(seconds: number) {
    const pad = (num: number) => (num < 10) ? '0' + num : num.toString();

    const minValue = Math.floor(seconds / 60);
    const secondsValue = Math.floor(seconds % 60);
    
    return pad(minValue) + ':' + pad(secondsValue);
}

export function trunc(stringValue: string, count: number) {

    const text = stringValue?.toString() || '';
    if (!text || !text.trim().length) return '';

    let chars = 0;
    const cleanSpaces = text.replace('  ', ' ');
    const allWords = cleanSpaces.split(' ');
    const words = allWords.filter(w => (chars += w.length) < count);
    const hasTrunc = words.length < allWords.length;
    const endChars = hasTrunc ? ' ...' : '';

    //if there is only one long word, cut it till count chars
    if (words.length == 0 && allWords.length > 0)
        words.push(text.slice(0, count));

    return words.join(' ') + endChars;
}

export function capitalize(textValue: string) {
    return textValue.slice(0, 1).toUpperCase() + textValue.slice(1);
}

export function cleanHtml(html: string) {
    return html
        .replace(/\<div\>(.*)<\/div>/g, '')
        .replace(/\<br [^\>]*\>/g, '<br>')
        .replace(/\<i [^\>]*\>/g, '<i>')
        .replace(/\<b [^\>]*\>/g, '<b>')
        .replace(/\<u [^\>]*\>/g, '<u>')
}